.tech{
    padding-bottom: 200px;
    .content{
        max-width: 1200px;
        margin: 0 auto;
        width: 100%;
        padding: 100px 50px;
        @media (max-width: 767px) {
            padding: 50px 20px 150px;
        }
    }
    .banner{
        background: url('../../assets/images/tech/tech1.png') no-repeat;
        background-size: 50%;
        background-position: 105% 0;
        display: flex;  
        align-items: center;
        padding-bottom: 40px;
        @media (max-width: 992px) {
            background-position: 105% 0;
        }
        @media (max-width: 767px) {
            background-size: 60%;
            background-position: 100% 100%;
        }
    }
    h2{
        font-size: 48px;
        color: #111029;
        font-weight: 600;
        @media (max-width: 767px) {
            font-size: 24px;
        }
    }
    h3{
        color: #111029;
        text-align: center;
        font-weight: 600;
        font-size: 48px;
        @media (max-width: 767px) {
            font-size: 24px;
        }
    }
    h4{
        color: #727272;
        text-align: center;
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 10px;
        @media (max-width: 767px) {
            font-size: 18px;
        }
    }
    p{
        font-size: 16px;
        color: #727272;
        max-width: 666px;
        @media (max-width: 1200px) {
            max-width: 600px;
        }
        @media (max-width: 992px) {
            max-width: 500px;
        }
        @media (max-width: 767px) {
            font-size: 12px;
        }
    }
    .container{
        background: radial-gradient(75.22% 75.22% at 20.49% 12.79%, #FFFFFF 0%, rgba(228, 231, 233, 0.502295) 100%);
        p{
            text-align: center;
            margin: 0 auto 30px;

        }
    }
    .list{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 35px;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        padding: 0; 
        // @media (max-width: 992px) {
        //     grid-template-columns: repeat(2, 1fr);
        // }
        @media (max-width: 767px) {
            grid-template-columns: repeat(1, 1fr);
        }
        .item{
            padding: 30px 20px;
            background: #fff;
            height: 100%;
            border-radius: 40px;
            box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.2);
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            @media (max-width: 767px) {
                margin: 20px;
                height: auto;
            }
            // p{
            //     color: #727272;
            //     font-size: 14px;
            //     text-align: center;
            //     margin-bottom: 0;
            //     height: 100%;
            //     display: flex;
            //     align-items: center;
            // }
            img{
                height: 45px;
                display: block;
                margin: 15px auto;
            }
        }
    }
    .network{
        margin: 100px auto 0;
        display: block;
        width: 80%;
        @media (max-width: 992px) {
            width: 100%;
        }
        @media (max-width: 767px) {
            width: 100%;
        }
    }
    .tools{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 46px;
        grid-row-gap: 100px;
        margin-top: 120px;
        align-items: center;
        justify-content: center;
        @media (max-width: 992px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: 767px) {
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 20px;
            grid-row-gap: 60px;
        }
        &>div{
            border-radius: 40px;
            box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.2);
            position: relative;
            padding: 80px 40px 40px;
            color: #727272;
            text-align: center;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            margin: 0 auto;
            cursor: pointer;
            @media (max-width: 767px) {
                padding: 60px 20px 30px;
            }
            p{
                font-weight: 600;
                font-size: 24px;
                @media (max-width: 767px) {
                    font-size: 16px;
                }
            }
            .img{
                width: 120px;
                height: 120px;
                border-radius: 60px;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                position: absolute;
                top: 0%;
                left: 50%;
                transform: translate(-50%, -50%);
                padding: 30px;
                @media (max-width: 767px) {
                    width: 70px;
                    height: 70px;
                    padding: 20px;
                }
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
@primary-color: #5542F6;