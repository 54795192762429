.product{
    padding-bottom: 200px;
    .ant-btn-primary{
      background: #3C17B8;
      border-radius: 15px;
      height: 50px;
      padding: 0 40px;
    }
    .content{
        max-width: 1200px;
        margin: 0 auto;
        width: 100%;
        padding: 0 50px;
        @media (max-width: 767px) {
            padding: 0 20px;
        }
    }
    h2{
        font-size: 48px;
        text-align: center;
        margin-top: 65px;
        font-weight: 600;
        @media (max-width: 767px) {
          font-size: 24px;
        }
    }
    h3{
        color: #111029;
        font-size: 24px;
        text-align: center;
        margin-top: 120px;
        margin-bottom: 80px;
        font-weight: 600;
        @media (max-width: 767px) {
          font-size: 18px;
        }
    }
    p{
        font-size: 12px;
    }
    .product-box{
        .item{
            height: 100%;
            width: 100%;
            display: flex!important;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            background: rgba(219, 222, 225, 0.4);
            border-radius: 24px;
            margin-bottom: 40px;
            .btns{
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              grid-gap: 20px;
              .ant-btn-primary{
                width: 100%;
                padding: 0;
              }
            }
      
            @media (max-width: 767px) {
              width: 100%;
              height: auto;
              flex-direction: column;
              border-radius: 16px;
            }
            &>div{
              width: 100%;
              &:last-of-type{
                padding: 0 32px;
                @media (max-width: 767px) {
                  padding: 20px;
                }
              }
            }
            h3{
                color: #111029;
                font-size: 20px;
                font-weight: 600;
                text-align: left;
                margin:0 0 20px;
                @media (max-width: 767px) {
                  font-size: 18px;
                }
            }
            p{
              color: #000;
              text-align: left;
              font-size: 12px;
              padding: 0;
              @media (max-width: 767px) {
                font-size: 10px;
                line-height: 15px;
                text-align: left;
              }
            }
            .ant-btn{
              margin-top: 40px;
              @media (max-width: 767px) {
                margin-top: 20px;
              }
            }
            img{
              margin: 0 auto;
              display: block;
              width: 617px;
              height: auto;
              border-radius: 24px;
              box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.25);
              @media (max-width: 992px) {
                border-radius: 16px;
                height: 260px;
                width: auto;
              }
              @media (max-width: 767px) {
                width: 100%;
                height: auto;
                box-shadow: 4px 5px 6px  rgba(0, 0, 0, 0.25);
              }
            }
        }
    }
    .daos{
        display: grid;
        grid-template-columns: repeat(2, auto);
        grid-template-rows: repeat(2, auto);
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        padding: 0;
        background: url('../../assets/images/home/cube.png') no-repeat;
        background-position: center;
        background-size: 60%;
        @media (max-width: 767px) {
          grid-template-columns: repeat(1, auto);
          grid-template-rows: repeat(auto-fill, auto);
        }
        .item{
            width: 320px;
            height: 240px;
            margin: 40px 20px;
            padding: 48px 32px 32px;
            background: #fff;
            box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.2);
            border-radius: 40px;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            @media (max-width: 767px) {
              width: auto!important;
              height: auto;
              padding: 48px 40px 24px;
              margin: 60px 30px;
              border-radius: 24px;
            }
            .blue{
              border-radius: 60px;
              width: 90px;
              height: 90px;
              margin-top: -60px;
              margin: 0 auto;
              position: absolute;
              left: 50%;
              top:-48px;
              transform: translateX(-50%);
              display: flex;
              justify-content: center;
              align-content: center;
            }
            .blue{
              background: #0B1CB5;
            }
            img{
              margin: 0 auto;
              display: block;
              width: 56px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
            h3,p{
              text-align: center;
            }
            h3{
              color: #727272;
              font-size: 18px;
              margin-top: 10px;
              margin-bottom: 10px;
              font-weight: 600;
              @media (max-width: 1100px) {
                font-size: 16px;
              }
            }
            p{
              font-size: 14px;
              color: #727272;
              height: 100%;
              display: flex;
              align-items: center;
              margin-bottom: 0;
              @media (max-width: 767px) {
                font-size: 12px;
              }
            }
            .parameters{
              font-size: 12px!important;
            }
          }
    }
}
@primary-color: #5542F6;