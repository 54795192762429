.ecosystem {
    padding-bottom: 200px;

    .content {
        max-width: 1200px;
        margin: 0 auto;
        width: 100%;
        padding: 0 50px;

        @media (max-width: 767px) {
            padding: 0 20px;
        }

        .go {
            width: 252px;
            height: 50px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 50px;
            color: #0D1DB4;
            background: #FFFFFF;
            box-shadow: 3px 3px 20px #A3A3A3;
            border-radius: 15px;
            text-align: center;
            cursor: pointer;
        }
    }

    .banner {
        background: url("../../assets/images/ecosystem/ecosystem1.png") no-repeat,
            #0d1db4;
        background-size: 100%, 100%;
        height: 378px;
        display: flex;
        align-items: center;
        background-position: right bottom;
    }

    h2 {
        font-size: 48px;
        color: #fff;
        font-weight: 600;

        @media (max-width: 767px) {
            font-size: 24px;
        }
    }

    h3 {
        font-size: 18px;
        color: #fff;
        font-weight: 600;
        text-align: left;
        color: #111029;
        height: 27px;
        line-height: 27px;
        margin: 32px 0 16px 0;
    }

    p {
        font-size: 16px;
        color: #fff;

        @media (max-width: 767px) {
            font-size: 12px;
        }
    }

    .list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 50px;

        @media (max-width: 767px) {
            grid-template-columns: repeat(1, 1fr);
        }


        .item {
            margin: 0 20px 0;
            padding: 30px 20px;
            background: #fff;
            border-radius: 40px;
            box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.2);
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            @media (max-width: 767px) {
                margin: 20px;
            }

            h4 {
                color: #727272;
                font-size: 18px;
                text-align: center;
                font-weight: 600;
                margin-bottom: 0;
            }

            p {
                color: #727272;
                font-size: 14px;
                text-align: center;
                margin-bottom: 0;
                height: 100%;
                display: flex;
                align-items: center;
            }

            img {
                height: 45px;
                display: block;
                margin: 15px auto;
            }
        }
    }

    .list1 {
        .item {
            width: 350px;
            height: 280px;
        }
    }

    .list2 {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 60px;

        @media (max-width: 767px) {
            grid-template-columns: repeat(1, 1fr);
        }

        .item {
            width: 100%;
            height: 200px;
            margin: 0 auto;

            @media (max-width: 767px) {
                width: 80%;
            }
        }
    }
}

.Ecosystem_item {
    width: 100%;
    height: 280px;
    margin: 0 auto;

    padding: 32px 25px 32px 40px;
    background: #fff;
    border-radius: 40px;
    box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;


    @media (max-width: 767px) {
        width: 80%;
    }

    .item_img {
        height: 90px;
        width: 100%;
        // img {
        //     height: 80px;
        //     display: block;
        // }
    }

    .item_type {
        width: 100%;
        display: flex;
        justify-content: flex-start;

        &>div {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #0b1cb5;
            padding: 5px 16px;
            background: #E2F1FF;
            border-radius: 14px;
        }

        .type_category {
            margin-right: 4px;
        }


    }

    .item_desc {
        height: 42px;
        width: 100%;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* or 143% */


        color: #727272;
        margin: 16px 0 12px 0;
    }

    // img{
    //     height: 45px;
    //     display: block;
    //     margin: 15px auto;
    // }
    .ant-btn-primary {
        background: #0B1CB5;
        box-shadow: 3px 3px 20px #A3A3A3;
        border-radius: 15px;
        height: 34px;
        width: 140px;
        text-align: center;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        color: #FFFFFF;
    }
}

.filter {
    max-width: 1100px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media (max-width: 767px) {
        padding: 0 20px;
     }

    &>div {
        display: flex;
        margin-top: 32px;
        flex-wrap: wrap;

    }

    .filter_name {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: #111029;
    }

    .filter_item {
        height: 34px;
        padding: 5px 16px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        gap: 8px;
        background: #FFFFFF;
        border: 1px solid #0B1CB5;
        border-radius: 14px;
        color: #0B1CB5;
        margin:  0 4px;
        cursor: pointer;

        @media (max-width: 767px) {
            margin-bottom: 5px;

         }
    }

    .filter_item_active {
        background: #0B1CB5;
        color: #fff;
    }
}

.serach {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    @media (max-width: 767px) {
        padding: 0 20px;
     }
    .serach_box {
        width: 432px;
        height: 64px;
        margin-top: 30px;
        @media (max-width: 767px) {
            width: 100%;
            margin-top: 15px;
        }
    }

    .ant-input-affix-wrapper-lg {
        width: 432px;
        height: 64px;
        background: #FAFAFA;
        border-radius: 16px;
        border: none;
        @media (max-width: 767px) {
            width: 100%;
        }

    }
    .ant-input-affix-wrapper > input.ant-input {
        background: #FAFAFA;
        color: #808191;
        font-size: 14px;
        padding-left: 10px;

    }
}

@primary-color: #5542F6;